import htmx from 'htmx.org';
import Alpine from 'alpinejs';
import Cookies from 'js-cookie';
import Pace from 'pace-js';
import axios from 'axios';
import validate from '@colinaut/alpinejs-plugin-simple-validate';
import * as echarts from 'echarts';
import * as Sentry from '@sentry/browser';
import Hotjar from '@hotjar/browser';
import BpmnModeler from 'bpmn-js/lib/Modeler';
// @ts-expect-error - JSON import
import theme from '../echarts/echarts_theme.json';


declare global {
  interface Window {
    htmx: typeof htmx;/** HTMX for AJAX Handling **/
    Alpine: typeof Alpine;/** AlpineJS for JS Interactivity **/
    Cookies: typeof Cookies;/** Cookies for Cookie Handling **/
    axios: typeof axios;/** Axios for API Requests **/
    EXCALIDRAW_ASSET_PATH: string; /** Excalidraw Asset Path **/
    echarts: typeof echarts; /** ECharts for Data Visualization **/
    Sentry: typeof Sentry; /** Sentry for Error Tracking **/
    Hotjar: typeof Hotjar; /** Hotjar for Analytics **/
    bpmnJS: typeof BpmnModeler; /** BPMN JS for BPMN Diagrams **/
  }
}
window.htmx = htmx;

const doubleRequestAnimationFrame = (callback) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(callback)
  })
}

export function $forceNextTick(callback = () => {}) {
  if (callback && typeof callback === 'function') {
    doubleRequestAnimationFrame(callback)
  } else {
    return new Promise(resolve => {
      doubleRequestAnimationFrame(resolve)
    })
  }
}
Alpine.plugin(validate);
Alpine.magic('forceNextTick', () => $forceNextTick)
window.Alpine = Alpine;
Alpine.start();
window.Cookies = Cookies;
Pace.start();

window.axios = axios;
window.axios.defaults.headers.common['X-CSRFTOKEN'] = document.head.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

window.EXCALIDRAW_ASSET_PATH = "/static/frontend/assets/excalidraw/";

window.echarts = echarts;
window.echarts.registerTheme('AESSIA', theme);
